import React from "react";
import { useTheme } from "@mui/material";

export function DiffChart(): React.ReactElement {
  const theme = useTheme();

  return (
    <svg
      height="75"
      viewBox="0 0 75 75"
      width="75"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 1785" transform="translate(-480.5 -3959)">
        <circle
          cx="37.5"
          cy="37.5"
          data-name="Ellipse 288"
          fill={theme.palette.primary.light}
          r="37.5"
          transform="translate(480.5 3959)"
        />
        <path
          d="M221.136 307h-5.182a.862.862 0 01-.864-.861v-29.278a.863.863 0 01.864-.861h5.182a.863.863 0 01.864.861v29.278a.862.862 0 01-.864.861zm-.864-29.278h-3.455v27.556h3.455zM210.773 307h-5.182a.862.862 0 01-.864-.861v-15.5a.863.863 0 01.864-.861h5.182a.863.863 0 01.864.861v15.5a.862.862 0 01-.864.861zm-.864-15.5h-3.455v13.778h3.455zm-9.5 15.5h-5.182a.862.862 0 01-.864-.861v-25.833a.863.863 0 01.864-.861h5.182a.863.863 0 01.864.861v25.833a.862.862 0 01-.864.861zm-.864-25.833h-3.455v24.111h3.455zm-9.5 25.833h-5.182a.862.862 0 01-.864-.861v-10.333a.863.863 0 01.864-.861h5.182a.863.863 0 01.864.861v10.333a.862.862 0 01-.864.861zm-.864-10.333h-3.455v8.611h3.455z"
          data-name="Bar Chart@2x.png"
          fill={theme.palette.primary.contrastText}
          fillRule="evenodd"
          transform="translate(315 3705)"
        />
      </g>
    </svg>
  );
}
